<template>
  <div class="dashboard_class" v-loading="totalLoading">
    <!--筛选-->
    <el-card :body-style="{ display: 'flex' }" style="margin-bottom: 10px">
      <div style="margin-right: 100px">
        <span class="labelTitle">选择时间：</span>
        <el-date-picker
          size="small"
          v-model="duration"
          @change="initData"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
          :shortcuts="shortcuts"
          :disabled-date="disabledDate"
        >
        </el-date-picker>
      </div>
      <div style="margin-right: 100px; margin-top: 2px">
        <span class="labelTitle">选择品牌：</span>
        <el-select
          v-model="brand"
          placeholder="请选择"
          size="mini"
          @change="changeBrand"
        >
          <el-option
            v-for="item in brandOptions"
            :key="item.id"
            :label="item.brandName"
            :value="item.brandName"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-right: 100px; margin-top: 2px">
        <span class="labelTitle">选择品线：</span>
        <el-select
          v-model="brandLine"
          placeholder="请选择"
          size="mini"
          @change="changeBrandLine"
        >
          <el-option
            v-for="item in brandLineOptions"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-right: 100px; margin-top: 2px">
        <span class="labelTitle">选择产品：</span>
        <el-select
          filterable
          v-model="product"
          @change="initData"
          placeholder="All"
          multiple
          collapse-tags
          size="mini"
        >
          <el-option
            v-for="item in productOptions"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </el-card>
    <!--KOL投放概览-->
    <el-card
      style="margin-bottom: 10px"
      :body-style="{ display: 'flex', flexDirection: 'column' }"
    >
      <p class="item_title">KOL投放概览</p>
      <div style="display: flex">
        <div class="imgWrap" style="zoom: 0.5">
          <canvas id="canvas"></canvas>
          <img v-if="logo" class="imgClass" :src="logo" alt="" />
        </div>
        <!-- kol数目 -->
        <div class="item_square_content">
          <div class="space_between_item">
            <svg-icon class-name="dashboard_kols" class="icon_svg" />
            <p>
              合计
              <count-to
                class="big_text"
                :endVal="quantityData.kolNum"
                :duration="1000"
              />
            </p>
          </div>
          <div class="space_between_item gray_text">
            <span></span>
            <p class="big_text pText">
              平均
              <count-to
                style="vertical-align: baseline"
                :endVal="quantityData.kolNumAvg * 1"
                :duration="1000"
              />
            </p>
          </div>
          <div>
            <svg-icon class-name="black_line" class="line_svg" />
            <div class="space_between_item">
              <span class="normal_text">KOL数</span>
              <span class="normal_text gray_text">个</span>
            </div>
          </div>
        </div>
        <!-- 推文数 -->
        <div class="item_square_content">
          <div class="space_between_item">
            <svg-icon class-name="dashboard_posts" class="icon_svg" />
            <p>
              合计
              <count-to
                class="big_text"
                :endVal="quantityData.postNum"
                :duration="1000"
              />
            </p>
          </div>
          <div class="space_between_item gray_text">
            <span></span>
            <p class="big_text pText">
              平均
              <count-to
                style="vertical-align: baseline"
                :endVal="quantityData.postNumAvg * 1"
                :duration="1000"
              />
            </p>
          </div>
          <div>
            <svg-icon class-name="black_line" class="line_svg" />
            <div class="space_between_item">
              <span class="normal_text">推文数</span>
              <span class="normal_text gray_text">条</span>
            </div>
          </div>
        </div>
        <!-- 费用 -->
        <div class="item_square_content">
          <div class="space_between_item">
            <svg-icon class-name="dashboard_cost" class="icon_svg" />
            <count-to
              class="big_text"
              :endVal="quantityData.cost / 100"
              :duration="1000"
            />
          </div>
          <div class="space_between_item gray_text">
            <span></span>
          </div>
          <div>
            <svg-icon class-name="black_line" class="line_svg" />
            <div class="space_between_item">
              <span class="normal_text">费用</span>
              <span class="normal_text gray_text">元</span>
            </div>
          </div>
        </div>
        <!-- 曝光 -->
        <div class="item_square_content">
          <div class="space_between_item">
            <span class="specialIcom" style="background-color: #d77181">
              <svg-icon
                class-name="dashboard_impression"
                class="icon_svg impressionIcom"
              />
            </span>
            <count-to
              class="big_text"
              :endVal="quantityData.exposure"
              :duration="1000"
            />
          </div>
          <div class="space_between_item gray_text">
            <span></span>
          </div>
          <div>
            <svg-icon class-name="black_line" class="line_svg" />
            <div class="space_between_item">
              <span class="normal_text">曝光</span>
            </div>
          </div>
        </div>
        <!-- 互动 -->
        <div class="item_square_content">
          <div class="space_between_item">
            <span class="specialIcom" style="background-color: #dd8967">
              <svg-icon
                class-name="dashboard_engagement"
                class="icon_svg engagementIcon"
              />
            </span>
            <count-to
              class="big_text"
              :endVal="quantityData.engagement"
              :duration="1000"
            />
          </div>
          <div class="space_between_item gray_text">
            <span></span>
          </div>
          <div>
            <svg-icon class-name="black_line" class="line_svg" />
            <div class="space_between_item">
              <span class="normal_text">互动</span>
            </div>
          </div>
        </div>
        <!-- CPUV -->
        <div class="item_square_content">
          <div class="space_between_item">
            <span class="specialIcom" style="background-color: #597ed5"
              ><svg-icon class-name="dashboard_cpi" class="icon_svg"
            /></span>
            <count-to
              class="big_text"
              :endVal="quantityData.cpuv"
              :decimals="2"
              :duration="1000"
            />
          </div>
          <div class="space_between_item gray_text">
            <span></span>
          </div>
          <div>
            <svg-icon class-name="black_line" class="line_svg" />
            <div class="space_between_item">
              <span class="normal_text">CPUV</span>
            </div>
          </div>
        </div>
        <!-- CPE -->
        <div class="item_square_content">
          <div class="space_between_item">
            <span class="specialIcom" style="background-color: #6bacaf">
              <svg-icon class-name="dashboard_cpe" class="icon_svg" />
            </span>
            <count-to
              class="big_text"
              :endVal="quantityData.cpe"
              :decimals="2"
              :duration="1000"
            />
          </div>
          <div class="space_between_item gray_text">
            <span></span>
          </div>
          <div>
            <svg-icon class-name="black_line" class="line_svg" />
            <div class="space_between_item">
              <span class="normal_text">CPE</span>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 品牌投放汇总 -->
    <el-card
      :body-style="{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '10px',
      }"
      style="margin-bottom: 10px"
      v-loading="brandCardLoading"
    >
      <p class="item_title">品牌投放汇总</p>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
        "
      >
        <el-button
          type="info"
          plain
          size="mini"
          @click="handleBrandExpansion"
          style="border: none"
        >
          {{ brandLineExpansion.includes(false) ? "全部展开" : "全部收起" }}
          <i
            :class="
              brandLineExpansion.includes(false)
                ? 'el-icon-arrow-down'
                : 'el-icon-arrow-up'
            "
          ></i>
        </el-button>
      </div>
      <el-table
        :data="brandTableData"
        ref="brandLineTable"
        stripe
        row-key="name"
        :tree-props="{ children: 'details' }"
        @expand-change="brandExpandChange"
      >
        <el-table-column
          v-for="item in brandColData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :formatter="item.formatter"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <!--KOL投放节奏-->
    <el-card
      :body-style="{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }"
      style="margin-bottom: 10px"
      v-loading="echartLoading"
    >
      <p class="item_title" style="position: relative">
        KOL投放节奏
        <el-radio-group
          class="radioGroupClass"
          v-model="echartsChange"
          size="mini"
        >
          <el-radio-button label="brand">品牌</el-radio-button>
          <el-radio-button label="brandLine">品线</el-radio-button>
          <el-radio-button label="product">产品</el-radio-button>
          <el-radio-button label="campaign">活动</el-radio-button>
        </el-radio-group>
      </p>
      <div style="display: flex; justify-content: space-between">
        <div style="flex: 1; display: flex; align-items: flex-start">
          <el-select
            v-model="campaignFilter"
            multiple
            placeholder="请选择"
            size="mini"
            collapse-tags
            @change="handleFilter"
            class="dash_board_select"
          >
            <el-option
              v-for="item in campaignList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <div
            style="flex: 1; display: flex; flex-wrap: wrap; margin-left: 20px"
          >
            <legend-com
              v-for="legend in echartLegend"
              :key="legend.name"
              :data="legend"
            />
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <el-checkbox-group
            v-model="echartsType"
            :min="0"
            :max="2"
            style="margin-right: 40px"
          >
            <el-checkbox key="cost" label="cost">费用</el-checkbox>
            <el-checkbox key="exposure" label="exposure">曝光</el-checkbox>
            <el-checkbox key="engagement" label="engagement">互动</el-checkbox>
            <el-checkbox key="sales" label="sales">销量</el-checkbox>
            <el-checkbox key="searchRate" label="searchRate"
              >搜索指数
            </el-checkbox>
          </el-checkbox-group>

          <el-radio-group
            class="timeRadioGroup"
            v-model="echartsTime"
            size="mini"
          >
            <el-radio-button label="day">天</el-radio-button>
            <el-radio-button label="week">周</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <line-bar
        :xAxis="echartxAxis"
        :series="echartSeries"
        :color="echartColor"
        :yAxisNames="{
          firstName: echartsType[0],
          secondName: echartsType[1],
        }"
      />
    </el-card>
    <!--KOL投放分布-->
    <el-card
      :body-style="{ display: 'flex', flexDirection: 'column' }"
      style="margin-bottom: 10px"
      v-loading="tableLoading"
    >
      <p class="item_title">KOL投放分布</p>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
        "
      >
        <el-radio-group v-model="type" style="margin-right: 60px">
          <el-radio label="percent">占比</el-radio>
          <el-radio label="amount">数量</el-radio>
        </el-radio-group>
        <el-button
          type="info"
          plain
          size="mini"
          @click="handleExpansion"
          style="border: none"
        >
          {{ expansion.includes(false) ? "全部展开" : "全部收起" }}
          <i
            :class="
              expansion.includes(false)
                ? 'el-icon-arrow-down'
                : 'el-icon-arrow-up'
            "
          ></i>
        </el-button>
      </div>
      <el-table
        :data="tableData"
        ref="treeTable"
        stripe
        row-key="tier"
        :tree-props="{ children: 'details' }"
        @expand-change="expandChange"
      >
        <el-table-column
          v-for="item in colData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :formatter="item.formatter"
          :label-class-name="item.labelClassName"
          :class-name="item.className"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <!--供应商统计-->
    <el-card
      :body-style="{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 10,
      }"
      style="margin-bottom: 10px"
      v-loading="agencyLoading"
    >
      <p class="item_title">供应商统计</p>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
        "
      >
        <el-button
          type="info"
          plain
          size="mini"
          @click="handleAgencyExpansion"
          style="border: none"
        >
          {{ agencyExpansion.includes(false) ? "全部展开" : "全部收起" }}
          <i
            :class="
              agencyExpansion.includes(false)
                ? 'el-icon-arrow-down'
                : 'el-icon-arrow-up'
            "
          ></i>
        </el-button>
      </div>
      <el-table
        :data="agencyTableData"
        ref="agencyTable"
        stripe
        row-key="rank"
        :tree-props="{ children: 'details' }"
        @expand-change="agencyExpandChange"
      >
        <el-table-column
          v-for="item in agencyColData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :formatter="item.formatter"
          :label-class-name="item.labelClassName"
          :class-name="item.className"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <!--KOL数据详情 -->
    <el-card
      style="margin-bottom: 10px"
      :body-style="{ display: 'flex', flexDirection: 'column' }"
    >
      <p class="item_title">KOL数据详情</p>
      <div class="filterMenu" style="height: 40px">
        <crowedCom></crowedCom>
        <div class="rightFilter">
          <el-button @click="expandAll" class="buttonClass" size="mini"
            >{{ this.isExpand ? "全部收起" : "全部展开"
            }}<i
              style="margin-left: 5px"
              :class="
                !this.isExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
              "
            ></i
          ></el-button>
          <el-input
            style="margin: 0 25px"
            size="mini"
            v-model="searchWord"
            @keyup.enter="searchKol"
            placeholder="搜索KOL"
          >
            <template #suffix>
              <el-icon class="el-input__icon">
                <search />
              </el-icon>
            </template>
          </el-input>
          <el-button
            :loading="downLoading"
            @click="downDashBoardKol"
            class="buttonClass"
            size="mini"
            >数据下载
          </el-button>
        </div>
      </div>
      <div class="kolTable">
        <div class="siteList">
          <el-menu
            default-active="wechat"
            class="el-menu-vertical-demo"
            @select="siteSelect"
          >
            <el-menu-item
              :index="item.value"
              :key="item.value"
              v-for="item in this.siteList"
            >
              <svg-icon :class-name="item.value" class="siteSvg"></svg-icon>
            </el-menu-item>
          </el-menu>
        </div>
        <div v-loading="dataLoading" class="tableList">
          <tableList
            @selKol="selKol"
            @changeSort="changeSort"
            :kolListData="kolListData"
            :brand="brand"
            :isExpand="isExpand"
          ></tableList>
          <div class="pagination clearfix">
            <el-pagination
              background
              :current-page="listCurrentPage"
              :page-size="listPageSize"
              :total="listTotalSize"
              @current-change="handleCurrentChange"
              :page-sizes="[20, 50, 100]"
              @size-change="handleSizeChange"
              layout="prev, pager, next, jumper, sizes, total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { h } from 'vue';
import lineBar from "./lineBar";
import crowedCom from "./filter/crowedCom/crowedCom";
import tableList from "./table/table.vue";
import { Search } from "@element-plus/icons-vue";
// import oneDataAnnular from "@/components/echarts/oneDataAnnular";
import legendCom from "./legendCom";
import {
  getCampaignBrand,
  getCampaignBrandLine,
  getCampaignProduct,
} from "@/api/campaign";
import {
  getQuantity,
  getEffect,
  getDistribution,
  getTrend,
  getDashboardKol,
  getDashboardOverview,
  downDashBoardKol,
  getDashboardAgenct,
  getDashboardBrandInfo,
  getDashboardBrandLineByBrand,
  getDashboardProduct,
} from "@/api/dashboard";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "index",
  data() {
    return {
      kolList: [
        {
          data: "1",
          name: "2",
          address: "3",
        },
      ],
      disabledDate: (time) => {
        // 一天时间戳：24 * 60 * 60 * 1000 = 86400000
        return time.getTime() > Date.now() - 86400000;
      },
      durationOptions: [
        { label: "近3个月", value: "3mon" },
        { label: "近6个月", value: "6mon" },
        { label: "近12个月", value: "12mon" },
      ],
      // moment().subtract(3, 'months')
      duration: [
        moment().subtract(3, "months").format("yyyy-MM-DD"),
        moment().format("yyyy-MM-DD"),
      ],
      // duration: ["2021-01-01", "2022-01-01"],
      brandOptions: [{ id: "all", brandName: "All", comments: "all" }],
      brand: "All",
      brandLine: "All",
      quantityData: {},
      effectData: {
        impression: 0,
        engagement: 0,
        cpi: null,
        cpe: null,
        engagementRate: null,
        sr: null,
      },
      type: "percent", // percent; amount
      // 手动加入rank字段，将平台改名为tier，details里的tier加工
      tableData: [],
      agencyTableData: [],
      brandColData: [
        {
          label: "品牌",
          prop: "name",
          sortable: false,
          width: null,
          align: "left",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (row.logoUrl && !row.logoUrl.includes("null")) {
              const platform = h('div', {
                style: 'display: inline-block;'
              }, [
                h('img', {
                  style: 'width: 30px; height: 30px',
                  src: process.env.VUE_APP_API_HOST + row.logoUrl
                }),
                h('span', { style: 'margin-left: 7px' }, cellValue)
              ]);

              return platform;
              // const platform = (
              //   <div style="display: inline-block;">
              //     <img
              //       style="width: 30px; height: 30px"
              //       src={process.env.VUE_APP_API_HOST + row.logoUrl}
              //     ></img>
              //     <span style="margin-left: 7px">{cellValue}</span>
              //   </div>
              // );
              // return platform;
            } else {
              return cellValue !== null ? cellValue : "--";
            }
          },
        },
        {
          label: "费用",
          prop: "cost",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null
              ? "￥" + this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          label: "KOL",
          prop: "kolNum",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "推文",
          prop: "postNum",
          sortable: false,
          width: 100,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "曝光",
          prop: "exposure",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "互动",
          prop: "engagement",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "CPUV",
          prop: "cpuv",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
          className: "dash_board_table_special_col",
        },
        {
          label: "CPE",
          prop: "cpe",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
          className: "dash_board_table_special_col",
        },
      ],
      brandTableData: [],
      colData: [
        {
          label: "Rank",
          prop: "rank",
          sortable: false,
          width: 70,
          align: "center",
          fixed: false,
        },
        {
          label: "平台",
          prop: "tier",
          sortable: false,
          width: 120,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            const cellValueStr = cellValue + "";
            if (cellValueStr.includes("KOC")) {
              return "KOC";
            } else if (cellValueStr.includes("Tier")) {
              return cellValueStr.slice(0, 5);
            } else {
              const platformIcon = `dashboard_platform_${cellValueStr}`;
              const platform = h('div', {
                style: 'display: flex; align-items: center;'
              }, [
                h('svg-icon', {
                  class: platformIcon,
                  style: 'margin-right: 10px; font-size: 20px !important;'
                }),
                h('span', {}, this.platformToZn(cellValueStr))
              ]);

              return platform;
              // const platform = (
              //   <div style="display: flex;align-items: center;">
              //     <svg-icon
              //       class-name={platformIcon}
              //       style="margin-right: 10px;font-size: 20px !important;"
              //     />
              //     <span>{this.platformToZn(cellValueStr)}</span>
              //   </div>
              // );
              // return platform;
            }
          },
        },
        {
          label: "费用",
          prop: "cost",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null
              ? (cellValue + "").includes("%")
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          label: "KOL",
          prop: "kolNum",
          sortable: false,
          width: 100,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (this.type !== "amount") {
              return cellValue != null ? cellValue : "--";
            } else {
              return cellValue != null ? this.sliceDecimal(cellValue) : "--";
            }
          },
        },
        {
          label: "推文",
          prop: "postNum",
          sortable: false,
          width: 100,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (this.type !== "amount") {
              return cellValue != null ? cellValue : "--";
            } else {
              return cellValue != null ? this.sliceDecimal(cellValue) : "--";
            }
          },
        },
        {
          label: "曝光",
          prop: "exposure",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (this.type !== "amount") {
              return cellValue != null ? cellValue : "--";
            } else {
              return cellValue != null ? this.sliceDecimal(cellValue) : "--";
            }
          },
        },
        {
          label: "互动",
          prop: "engagement",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (this.type !== "amount") {
              return cellValue != null ? cellValue : "--";
            } else {
              return cellValue != null ? this.sliceDecimal(cellValue) : "--";
            }
          },
        },
        {
          label: "复投",
          prop: "readvertisement",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (this.type !== "amount") {
              return cellValue != null ? cellValue : "--";
            } else {
              return cellValue != null ? this.sliceDecimal(cellValue) : "--";
            }
          },
          labelClassName: "dash_board_table_special_col_header",
          className: "dash_board_table_special_col",
        },
        {
          label: "ER",
          prop: "engagementRate",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? cellValue : "--";
          },
          className: "dash_board_table_special_col",
        },
        {
          label: "CPUV",
          prop: "cpuv",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (this.type !== "amount") {
              return cellValue != null ? cellValue : "--";
            } else {
              return cellValue != null ? this.sliceDecimal(cellValue) : "--";
            }
          },
          className: "dash_board_table_special_col",
        },
        {
          label: "CPE",
          prop: "cpe",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (this.type !== "amount") {
              return cellValue != null ? cellValue : "--";
            } else {
              return cellValue != null ? this.sliceDecimal(cellValue) : "--";
            }
          },
          className: "dash_board_table_special_col",
        },
      ],
      agencyColData: [
        {
          label: "供应商",
          prop: "platform",
          sortable: false,
          width: null,
          align: "left",
          fixed: false,
          formatter: (row, column, cellValue) => {
            if (
              [
                "wechat",
                "weibo",
                "douyin",
                "xiaohongshu",
                "kuaishou",
                "bilibili",
              ].indexOf(row.platform) !== -1
            ) {
              const cellValueStr = cellValue + "";
              const platformIcon = `dashboard_platform_${cellValueStr}`;
              const platform = h('div', {
                style: 'position: absolute; top: 50%; left: 30%; transform: translateY(-50%)'
              }, [
                h('svg-icon', {
                  class: platformIcon,
                  style: 'margin-right: 10px; font-size: 20px !important;'
                }),
                h('span', {}, this.platformToZn(cellValueStr))
              ]);

              // const platform = (
              //   <div style="position: absolute; top: 50%; left: 30%; transform:translateY(-50%)">
              //     <svg-icon
              //       class-name={platformIcon}
              //       style="margin-right: 10px;font-size: 20px !important;"
              //     />
              //     <span>{this.platformToZn(cellValueStr)}</span>
              //   </div>
              // );
              return platform;
            } else {
              return cellValue ? cellValue : "--";
            }
          },
        },
        {
          label: "费用",
          prop: "cost",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null
              ? "￥" + this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          label: "KOL",
          prop: "kolNum",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "推文",
          prop: "postNum",
          sortable: false,
          width: 100,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "曝光",
          prop: "exposure",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "互动",
          prop: "engagement",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "CPUV",
          prop: "cpuv",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "CPE",
          prop: "cpe",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue != null ? this.sliceDecimal(cellValue) : "--";
          },
        },
      ],
      echartsType: ["cost"],
      echartsTime: "day",
      echartsChange: "brand",
      expansion: [],
      brandLineExpansion: [],
      agencyExpansion: [],
      campaignFilter: [],
      campaignList: [],
      echartLegend: [],
      echartxAxis: {
        data: [
          201905, 201906, 201907, 201908, 201909, 201910, 201911, 201912,
          202001, 202002,
        ],
        type: "category",
      },
      echartSeries: [],
      echartColor: ["#7CA526", "#5E9AF4", "#5B4A99"],
      defaultColors: [
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ],
      originData: [],
      filterOriginDate: [],
      totalLoading: true,
      tableLoading: false,
      echartLoading: false,
      kolListData: [],
      sortColumn: "taPostRead",
      sort: 0,
      dataLoading: false,
      listCurrentPage: 1,
      listPageSize: 20,
      listTotalSize: 0,
      searchWord: "",
      kolSite: "wechat",
      downloadKolList: [],
      selKolList: [],
      isExpand: false,
      logo: null,
      downLoading: false,
      siteList: [],
      shortcuts: [
        {
          text: "最近10天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
            return [start, end];
          },
        },
        {
          text: "最近一个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      // [{ id: "all", brandName: "All", comments: "all" }]
      brandLineOptions: [{ label: "All", value: "All" }],
      productOptions: [],
      product: [],
    };
  },
  computed: {
    ...mapGetters(["month", "dashBoardCrowdSeg"]),
  },
  async mounted() {
    this.getCampaignBrandFunc();
    this.getCampaignBrandLineFunc();
    this.getCampaignProductFunc();
    this.siteList = this.$storage.getItem("sites");
    this.initData();
  },
  methods: {
    async getCampaignBrandFunc() {
      let { code, data } = await getCampaignBrand();
      if (code === 0) {
        data.forEach((v) => {
          this.brandOptions.push(v);
        });
      } else {
        this.$message.error("请求失败，请联系管理员");
      }
    },
    async getCampaignBrandLineFunc() {
      let { code, data } = await getCampaignBrandLine();
      if (code === 0) {
        data.forEach((v) => {
          this.brandLineOptions.push({
            label: v,
            value: v,
          });
        });
      } else {
        this.$message.error("请求失败，请联系管理员");
      }
    },
    async getCampaignProductFunc() {
      let { code, data } = await getCampaignProduct();
      if (code === 0) {
        data.forEach((v) => {
          this.productOptions.push({
            label: v,
            value: v,
          });
        });
      } else {
        this.$message.error("请求失败，请联系管理员");
      }
    },
    changeBrand() {
      // 更新brandline
      this.getDashboardBrandLineByBrandFuc();
      // 更新product
      this.getDashboardProductFuc();
      this.initData();
    },
    switchToEn(str) {
      switch (str) {
        case "cost":
          return "费用";
        case "exposure":
          return "曝光";
        case "engagement":
          return "互动";
        case "sales":
          return "销量";
        case "searchRate":
          return "搜索指数";
      }
    },
    async getDashboardBrandLineByBrandFuc() {
      let { code, data } = await getDashboardBrandLineByBrand({
        brand: this.brand,
      });
      if (code === 0) {
        this.brandLineOptions = [{ label: "All", value: "All" }];
        data.forEach((v) => {
          this.brandLineOptions.push({
            label: v,
            value: v,
          });
        });
        // 判断已选择的brandline在不在列表中
        if (this.brandLine !== "All" && !data.includes(this.brandLine)) {
          this.brandLine = "All";
        }
      }
    },
    changeBrandLine() {
      // 更新product
      this.getDashboardProductFuc();
      this.initData();
    },
    async getDashboardProductFuc() {
      let { code, data } = await getDashboardProduct({
        brand: this.brand,
        brandLine: this.brandLine,
      });
      if (code === 0) {
        this.productOptions = [];
        data.forEach((v) => {
          this.productOptions.push({
            label: v,
            value: v,
          });
        });
        // 判断已选择的product在不在列表中
        if (this.product.length != 0) {
          this.product = this.product.filter((e) => data.includes(e));
        }
      }
    },
    async getDashboardBrandInfoFunc() {
      let { code, data } = await getDashboardBrandInfo({
        brand: this.brand,
        brandLine: this.brandLine,
        product: this.product.toString(),
        startDate: this.duration[0],
        endDate: this.duration[1],
      });
      if (code === 0) {
        data.forEach((brand, i) => {
          if (brand.details && brand.details.length) {
            brand.rank = i + 1;
            this.brandLineExpansion[i] = false;
            brand.details.forEach((brandLine) => {
              if (brandLine.details && brandLine.details.length) {
                brandLine.rank = i * 10 + 1;
                // brandLine.brand = brandLine.brandLine;
                this.brandLineExpansion[i * 10] = false;
                // brandLine.details.forEach((product) => {
                //   product.brand = product.product;
                // });
              } else {
                delete brandLine.details;
              }
            });
          } else {
            delete brand.details;
          }
        });
        this.brandTableData = data;
      }
    },
    async getDashboardAgencyFunc() {
      let { code, data } = await getDashboardAgenct({
        brandLine: this.brandLine,
        brand: this.brand,
        product: this.product.toString(),
        startDate: this.duration[0],
        endDate: this.duration[1],
      });
      if (code === 0) {
        data.forEach((item, i) => {
          item.rank = i + 1;
          item.platform = item.agency;
          item.details && item.details.length
            ? (this.agencyExpansion[i] = false)
            : (this.agencyExpansion[i] = null);
        });
        this.agencyTableData = data;
      }
    },
    expandAll() {
      this.isExpand = !this.isExpand;
    },
    async downDashBoardKol() {
      this.downLoading = true;
      this.$message({
        type: "info",
        message: "文件正在解析，请耐心等候",
      });
      const params = {
        startDate: this.duration[0],
        endDate: this.duration[1],
        brandLine: this.brandLine,
        brand: this.brand,
        product: this.product.toString(),
        seg: this.dashBoardCrowdSeg,
        platform: this.kolSite,
        mKolids: this.downloadKolList.join(","),
      };
      downDashBoardKol(params, "KOL数据详情.xlsx")
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载，请耐心等待",
          });
          this.downLoading = false;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    siteSelect(index) {
      this.kolSite = index;
      this.getDashboardKol(true);
    },
    searchKol() {
      this.getDashboardKol();
    },
    handleSizeChange(val) {
      this.listPageSize = val;
      this.getDashboardKol();
    },
    handleCurrentChange(val) {
      this.listCurrentPage = val;
      this.getDashboardKol();
    },
    selKol(kolList, rowList) {
      this.downloadKolList = kolList;
      this.selKolList = rowList;
    },
    changeSort(col, label) {
      if (col === this.sortColumn) {
        this.sort = 1 - this.sort;
      } else {
        this.sortColumn = col;
        this.sort = 0;
      }
      this.getDashboardKol();
    },
    initData() {
      this.totalLoading = true;
      // this.getQuantity();
      // this.getEffect();
      this.getTableData();
      this.getTrend();
      this.totalLoading = false;
      this.draw();
      this.getDashboardOverview();
      this.getDashboardBrandInfoFunc();

      this.getDashboardKol();
      this.getDashboardAgencyFunc();
    },
    async getDashboardOverview() {
      console.log(this.brand);
      let res = await getDashboardOverview({
        brand: this.brand,
        // 开始日期
        startDate: this.duration[0],
        // 结束日期
        endDate: this.duration[1],
        // 品线
        brandLine: this.brandLine,
        product: this.product.toString(),
      });
      if (res.code === 0) {
        console.log(res.data);
        this.quantityData = res.data;
        console.log(this.quantityData);
        if (res.data.logo.includes("null")) {
          this.logo = null;
        } else {
          this.logo = process.env.VUE_APP_API_HOST + res.data.logo;
        }
      } else {
        this.$message.error("请求数据失败，请联系管理员");
      }
    },
    async getDashboardKol(isConcantSelKol) {
      this.dataLoading = true;
      // console.log(this.dashBoardCrowdSeg);
      let res = await getDashboardKol({
        startDate: this.duration[0],
        endDate: this.duration[1],
        brandLine: this.brandLine,
        brand: this.brand,
        product: this.product.toString(),
        seg: this.dashBoardCrowdSeg,
        platform: this.kolSite,
        size: this.listPageSize,
        page: this.listCurrentPage,
        searchWord: this.searchWord,
        sortColumn: this.sortColumn,
        sortDirection: this.sort === 0 ? "desc" : "asc",
      });
      if (res.code === 0) {
        if (!isConcantSelKol) {
          // 选中的kol在切换页码后保留
          if (this.selKolList.length !== 0) {
            res.data.kolInfoList.unshift(...this.selKolList);
            let obj = {};
            // 数组中去除重复对象
            res.data.kolInfoList = res.data.kolInfoList.reduce((arr, item) => {
              obj[item.mKolid]
                ? ""
                : (obj[item.mKolid] = true && arr.push(item));
              return arr;
            }, []);
          }
        }
        this.kolListData = res.data.kolInfoList;
        this.listTotalSize = res.data.total;
        this.dataLoading = false;
      }
    },
    draw() {
      const canvas = document.getElementById("canvas");
      const context = canvas.getContext("2d");
      canvas.width = 300;
      canvas.height = 300;
      context.beginPath();
      context.lineWidth = 10;
      context.strokeStyle = "#66a6e9";
      context.arc(150, 150, 136, 0, 2 * Math.PI);
      context.stroke();
      context.closePath();
    },
    async getQuantity() {
      const params = {
        duration: this.duration,
        brand: this.brand,
      };
      const { data } = await getQuantity(params);
      this.quantityData = data;
    },
    async getEffect() {
      const params = {
        duration: this.duration,
        brand: this.brand,
      };
      const { data } = await getEffect(params);
      this.effectData = data;
    },
    expandChange(row, expanded) {
      console.log(row);
      const index = row.rank - 1;
      this.expansion[index] = expanded;
    },
    agencyExpandChange(row, expanded) {
      const index = row.rank - 1;
      this.agencyExpansion[index] = expanded;
    },
    brandExpandChange(row, expanded) {
      console.log(row, expanded);
      const index = row.rank - 1;
      this.brandLineExpansion[index] = expanded;
      console.log(this.brandLineExpansion);
    },
    handleBrandExpansion() {
      const expanded = this.brandLineExpansion.includes(false);
      this.brandTableData.forEach((item) => {
        this.$refs.brandLineTable.toggleRowExpansion(item, expanded);
        if (item.details && item.details.length) {
          item.details.forEach((brandLine) => {
            this.$refs.brandLineTable.toggleRowExpansion(brandLine, expanded);
          });
        }
      });
    },
    handleAgencyExpansion() {
      const expanded = this.agencyExpansion.includes(false);
      this.agencyTableData.forEach((item) => {
        this.$refs.agencyTable.toggleRowExpansion(item, expanded);
      });
    },
    // 投放分布的展开切换
    handleExpansion() {
      const expanded = this.expansion.includes(false);
      this.tableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, expanded);
      });
    },
    // 投放分布的数据
    async getTableData() {
      const params = {
        startDate: this.duration[0],
        endDate: this.duration[1],
        brandLine: this.brandLine,
        brand: this.brand,
        type: this.type,
        mon: this.month,
        product: this.product.toString(),
      };
      const { data } = await getDistribution(params);
      data.forEach((item, i) => {
        item.rank = i + 1;
        item.tier = item.platform;
        // tier的唯一性
        if (item.details && item.details.length) {
          // 将koc放到最后
          if (item.details[0].tier === "0") {
            item.details.push(item.details.splice(0, 1)[0]);
          }
          item.details.forEach((tier) => {
            tier.tier =
              (tier.tier === "0" ? "KOC" : "Tier" + tier.tier) +
              Math.random().toFixed(3);
          });
        } else {
          delete item.details;
        }
        item.details && item.details.length
          ? (this.expansion[i] = false)
          : (this.expansion[i] = null);
      });
      this.tableData = data;
      // 全部收起
      this.tableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, false);
      });
    },
    // 投放节奏的筛选
    handleFilter() {
      this.filterOriginDate = this.campaignFilter.map((filter) =>
        this.originData.find((campaign) => campaign.trendType === filter)
      );
      this.initTrendData(this.filterOriginDate);
    },
    async getTrend() {
      const params = {
        brand: this.brand,
        xType: this.echartsTime,
        yType:
          this.echartsType.length === 0 ? null : this.echartsType.join(","),
        startDate: this.duration[0],
        endDate: this.duration[1],
        brandLine: this.brandLine,
        product: this.product.toString(),
        type: this.echartsChange,
      };
      let { code, data } = await getTrend(params);
      if (code === 0) {
        if (data === null) {
          data = [];
        }
        data = data.filter((item) => item.trend.length);
        this.originData = [].concat(data);
        this.filterOriginDate = [].concat(data);
        console.log(this.filterOriginDate, "this.filterOriginDate");
        this.campaignList = [];
        this.campaignFilter = [];
        data.forEach((campaign) => {
          this.campaignList.push(campaign.trendType);
          this.campaignFilter = [].concat(this.campaignList);
        });
        this.initTrendData(data);
      } else {
        this.$message.error("请求失败，请联系管理员");
      }
    },
    initTrendData(data) {
      console.log(data);
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign, i) => {
        this.echartLegend.push({
          name: campaign.trendType,
          color: this.echartColor.concat(this.defaultColors)[i],
        });
        startTimeArr.push(campaign.trend[0].xAxis);
        const lastIndex = campaign.trend.length - 1;
        endTimeArr.push(campaign.trend[lastIndex].xAxis);
      });
      if (this.echartsTime === "day") {
        // day
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    formatDayDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime);
    },
    getDayAllDate(start, end) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp; stamp <= stampDe; stamp += oneDay) {
        dateArr.push(this.timeStampToDate(stamp));
      }
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatWeekDate(startTimeArr, endTimeArr) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getWeekAllDate(startTime, endTime);
    },
    getWeekAllDate(start, end) {
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stamp; stamp <= stampDe; stamp += oneWeek) {
        stampArr.push(stamp);
      }
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatMonthDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getMonthAllDate(startTime, endTime);
    },
    getMonthAllDate(start, end) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    timeStampToDate(timestamp) {
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      let that = this;
      this.echartSeries = [];
      this.filterOriginDate.forEach((campaign) => {
        const seriesItem = {
          data: [],
          name: campaign.trendType,
          type: "line",
          smooth: true,
          selType: that.echartsType[0],
          opacity: false,
          tooltip: {
            valueFormatter: function (value) {
              return that.switchToEn(that.echartsType[0]) + "-" + value;
            },
          },
        };
        const seriesItem2 = {
          data: [],
          name: campaign.trendType,
          type: "line",
          smooth: true,
          yAxisIndex: 1,
          selType: that.echartsType[1],
          opacity: true,
          lineStyle: {
            opacity: 0.5,
            width: 5,
          },
          tooltip: {
            valueFormatter: function (value) {
              return that.switchToEn(that.echartsType[1]) + "-" + value;
            },
          },
        };
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        const startTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[0].xAxis
        );
        const endTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[campaign.trend.length - 1].xAxis
        );
        const campaignxAxis = this.echartxAxis.data.slice(
          startTimeIndex,
          endTimeIndex + 1
        );
        seriesItem.data = this.echartxAxis.data.map((xAxis) => {
          let data = null;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend ? (data = trend.yAxis) : (data = 0);
          }
          return data;
        });
        seriesItem2.data = this.echartxAxis.data.map((xAxis) => {
          let data = null;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend ? (data = trend.yAxis2) : (data = 0);
          }
          return data;
        });
        console.log(seriesItem, "seriesItem");

        if (this.echartsType.length === 2) {
          this.echartSeries.push(seriesItem2);
        }
        this.echartSeries.push(seriesItem);
      });
      console.log(this.echartSeries, "echartSeries");
    },
  },
  watch: {
    // searchWord() {
    //   this.getDashboardKol();
    // },
    dashBoardCrowdSeg() {
      this.getDashboardKol();
    },
    type() {
      this.tableLoading = true;
      this.getTableData();
      this.tableLoading = false;
    },
    echartsChange() {
      this.echartLoading = true;
      this.getTrend();
      this.echartLoading = false;
    },
    echartsTime() {
      this.echartLoading = true;
      this.getTrend();
      this.echartLoading = false;
    },
    echartsType() {
      this.echartLoading = true;
      this.getTrend();
      this.echartLoading = false;
    },
  },
  components: {
    lineBar,
    // oneDataAnnular,
    legendCom,
    crowedCom,
    Search,
    tableList,
  },
};
</script>

<style scoped lang="scss">
.radioGroupClass {
  position: absolute;
  top: 5px;
  right: 0;
}

.labelTitle {
  font-weight: 600;
}

.buttonClass {
  color: #909399;
  background-color: #f4f4f5;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.pagination {
  text-align: center;
  margin-top: 10px;

  .dataTime {
    float: left;
    font-size: $font-size-medium;
    line-height: 30px;
    color: $color-sub-title;
  }
}

.imgWrap {
  position: relative;
  width: 300px;
  float: left;
  margin-right: 20px;

  .imgClass {
    position: absolute;
    left: 51%;
    top: 46%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
}

.item_title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.item_square_content {
  width: 200px;
  height: 160px;
  // border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .space_between_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }

  .specialIcom {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
  }

  .icon_svg {
    font-size: 30px;
  }

  .engagementIcon {
    font-size: 24px;
    position: relative;
    top: 3px;
    left: 3px;
  }

  .impressionIcom {
    font-size: 24px;
    position: relative;
    top: 3px;
    left: 3px;
  }

  .line_svg {
    width: 100%;
    margin-bottom: 4px;
  }

  .big_text {
    font-size: 16px;
    margin-left: 5px;
  }

  .pText {
    font-size: 16px;
  }

  .normal_text {
    font-size: 16px;
    color: #aeaaaa;
  }

  .gray_text {
    color: #aeaaaa;
  }
}

.item_color_content {
  width: 171px;
  height: 200px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .icon_svg {
    font-size: 46px;
  }

  .line_svg {
    width: 100%;
  }

  .white_text {
    color: #fff;
  }

  .big_text {
    font-size: 20px;
    margin-bottom: 4px;
  }

  .normal_text {
    font-size: 18px;
  }
}

.item_annular_content {
  position: relative;

  .annular_content {
    width: 170px;
    height: 170px;
  }

  .tooltip_content {
    width: 100px;
    height: 90px;
    position: absolute;
    top: 27%;
    left: 23%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tooltip_number {
      font-size: 30px;
      color: #11263c;
      margin-bottom: 6px;
    }

    .tooltip_text {
      font-size: 26px;
      color: #929598;
      margin-right: 4px;
    }

    .tooltip_icon {
      font-size: 18px;
    }
  }
}

.filterMenu {
  display: flex;
  justify-content: space-between;

  .rightFilter {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.kolTable {
  display: flex;
  // justify-content: space-between;
  .siteList {
    width: 45px;

    .siteSvg {
      width: 18px;
      height: 18px;
    }
  }

  .tableList {
    flex: 1;
  }
}
</style>
<style lang="scss">
.timeRadioGroup .el-radio-button__inner {
  padding: 7px 21.5px !important;
}

.dash_board_table_special_col_header {
  > div {
    border-left: 1px solid #ece9f1;
  }
}

.dash_board_table_special_col {
  background-color: #f2f6f9;
}

.dashboard_class {
  .el-checkbox_input.is-focus .el-checkbox_inner {
    border-color: #e09a29 !important;
  }

  .el-checkbox_inner:hover {
    border-color: #e09a29 !important;
  }
}

.dashboard_class {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #e09a29 !important;
    border-color: #e09a29;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #e09a29;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #e09a29;
  }

  .el-checkbox__inner:hover {
    border-color: #e09a29;
  }

  .expandWrapper table td,
  .expandWrapper table th {
    padding: 5px;
  }

  .tableList {
    .el-table .cell {
      padding: 0;
    }
  }

  .el-menu.el-menu-vertical-demo {
    border: none;
  }

  .el-menu.el-menu-vertical-demo .el-menu-item {
    padding-left: 10px !important;
  }

  .el-radio-group {
    .is-checked {
      .el-radio__inner {
        background: #e09a29;
      }
    }

    .el-radio {
      .el-radio__input {
        .el-radio__inner {
          border-color: #e09a29;
        }
      }

      .el-radio__label {
        color: #979797;
      }
    }

    .is-active {
      .el-radio-button__inner {
        background-color: #e09a29 !important;
        border-color: #e09a29;
        color: #fff !important;
      }
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      box-shadow: -1px 0 0 0 #e09a29;
    }

    .el-radio-button__original-radio:checked + .el-radio-button__inner {
      box-shadow: -1px 0 0 0 #e09a29;
    }

    .el-radio-button {
      .el-radio-button__inner {
        background-color: #f8f9fe;
        color: #979797;
      }
    }
  }
}
</style>
